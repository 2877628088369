require('./bootstrap');

jQuery(window).on('load', function() {
    setTimeout(() => {
        jQuery('.animinput[autofocus]').parents('.input_wrap').addClass('input-focused');
    }, 100);
    jQuery.ready.then(function() {
        // for detect input autofill from browser
        setTimeout(() => {
            jQuery('.animinput:-webkit-autofill').each(function() {
                var ele = jQuery(this);
                ele.parents('.input_wrap').addClass('input-focused');
            });    
        }, 100);
        // for move label UP/DOWN on input focus/blur
        jQuery('.animinput').on('focus', function() {
            jQuery(this).parents('.input_wrap').addClass('input-focused');
        }).on('blur', function() {
            var ele = jQuery(this);
            if (ele.val() == '') {
                ele.parents('.input_wrap').removeClass('input-focused');
            }
        });
        // for move label UP where we have value on initial page load
        jQuery('.animinput').each(function() {
            var ele = jQuery(this);
            if (ele.val() != '') {
                ele.parents('.input_wrap').addClass('input-focused');
            }
        });
    });
});