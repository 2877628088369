class ShowPlatformCompanyCreate
{

    static init(formName)
    {
        this.newMethod();
        this.validateStep1(formName);
    }

    static initStep2(formName)
    {
        //this.chargebeeCheckout();

        this.newMethod();
        this.validateStep2(formName);
        this.validatePaymentForm(formName);
        this.validatePassword();
        this.showHidePassword();

        this.applyCoupon();
        this.removeCoupon();
        this.addCoupon();

        this.showSubdomainPreview();
    }

    static showSubdomainPreview()
    {
        const companyNameInput = document.getElementById('companyName');
        const subdomainPreview = document.getElementById('subdomainPreview');
        const subdomainPreviewContainer = document.getElementById('subdomainPreviewContainer');

        companyNameInput.addEventListener('keyup', function() {
            const companyName = this.value.trim();
            const subdomain = generateSubdomain(companyName);
            subdomainPreview.innerHTML = '<small>Your PodUp subdomain: ' + `<b>${subdomain}.podup.com</b></small>`;
            subdomainPreviewContainer.classList.toggle('d-none', companyName === '');
        });

        function generateSubdomain(companyName) {
            // Remove special characters and convert to lowercase
            const sanitizedName = companyName.toLowerCase().replace(/[^a-z0-9-]/g, '');
            // Truncate if exceeding character limit
            const trimmedName = sanitizedName.substring(0, 50);
            // Remove leading/trailing hyphens and consecutive hyphens
            const formattedName = trimmedName.replace(/^[-]+|[-]+$/g, '').replace(/--/g, '-');
            return formattedName;
        }
    }

    static newMethod()
    {
        jQuery.validator.addMethod('fieldUnique', function (value, element) {
            var isSuccess = '';
            var params = {};
            params[jQuery(element).attr("name")] = value;

            jQuery.ajax({
                url: "/api/v1/companies",
                type: "get",
                dataType: 'json',
                async: false,
                data: params,
                success: function (data) {
                    isSuccess = !data.data.isPresent;
                }
            });

            return isSuccess;
        }, 'Message');
        
        jQuery.validator.addMethod("alpha_num", function(value, element) {
            return this.optional(element) || /^[a-zA-Z0-9]+$/i.test(value);
        }, "The podcast name must only contain letters and numbers."); 

        //exiry year validation'
        jQuery.validator.addMethod('length2or4', function (element) {
            return element.length == 2 || element.length == 4 ? true : false;
        }, "Expiration year must be 2 or 4 digit long.");
        
        //cvv validation'
        jQuery.validator.addMethod('length3or4', function (element) {
            return element.length == 3 || element.length == 4 ? true : false;
        }, "CVV must be 3 or 4 digit long.");
    }

    static validateStep1(formName) {
        var rules = {
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            email: {
                required: true,
                email: true
            }
        };

        var messages = {
            first_name: {
                required: "First Name is required."
            },
            last_name: {
                required: "Last Name is required."
            },
            email: {
                required: "Email is required."
            }
        };

        var options = {
            rules: rules,
            messages: messages,
            errorClass: 'text-danger',
            onsubmit: true,
            debug: false
        };

        jQuery(formName).validate(options);
    }

    static validateStep2(formName) {
        var rules = {
            company_name: {
                required: true,
                fieldUnique: true,
                // alpha_num: true,
                maxlength: 50,
            },
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            phone_no: {
                required: true,
            },
            password: {
                required: true
            },
            confirm_password: {
                required: true,
                minlength: 8,
                equalTo: "#password"
            },
            address: {
                required: true
            },
            country: {
                required: true
            },
            state: {
                required: true
            },
            city: {
                required: true
            },
            zip_code: {
                required: true
            }
        };

        var messages = {
            company_name: {
                required: "Podcast Name is required.",
                fieldUnique: 'Podcast Name already in use.'
            },
            first_name: {
                required: "First Name is required."
            },
            last_name: {
                required: "Last Name is required."
            },
            email: {
                required: "Email is required."
            },
            phone_no: {
                required: "Phone is required."
            },
            password: {
                required: "Password is required."
            },
            confirm_password: {
                required: "Confirm Password is required.",
                equalTo: 'Password not matching.'
            },
            address: {
                required: "Address is required."
            },
            country: {
                required: "Country is required."
            },
            state: {
                required: "State is required."
            },
            city: {
                required: "City is required."
            },
            zip_code: {
                required: "Postal/Zip code is required."
            }
        };

        var options = {
            rules: rules,
            messages: messages,
            errorClass: 'text-danger',
            onsubmit: true,
            debug: false
        };

        jQuery(formName).validate(options);
    }
    
    static validatePaymentForm(formName) {
        var rules = {
            card_no: {
                required: true,
                maxlength: 16
            },
            expiry_month: {
                required: true,
                maxlength: 2
            },
            expiry_year: {
                required: true,
                maxlength: 4,
                length2or4: true
            },
            cvv: {
                required: true,
                maxlength: 4,
                length3or4: true
            }
        };

        var messages = {
            card_no: {
                required: "Card Number is required."
            },
            expiry_month: {
                required: "Expiration month is required."
            },
            expiry_year: {
                required: "Expiration year is required."
            },
            cvv: {
                required: "CVV is required."
            }
        };

        var options = {
            rules: rules,
            messages: messages,
            errorClass: 'text-danger',
            onsubmit: true,
            errorContainer: "#errorContainer",
            errorLabelContainer: "#errorLabelContainer",
            wrapper: "li",
            errorElement: "div",
            debug: false
        };

        jQuery(formName).validate(options);     
    }

    static validatePassword() {
        var myInput = document.getElementById("password");
        var letter = document.getElementById("letter");
        var capital = document.getElementById("capital");
        var number = document.getElementById("number");
        var length = document.getElementById("length");
        var specialcharacter = document.getElementById("specialcharacter");

        // When the user clicks on the password field, show the message box
        myInput.onfocus = function () {
            document.getElementById("message").style.display = "block";
        }

        // When the user clicks outside of the password field, hide the message box
        myInput.onblur = function () {
            document.getElementById("message").style.display = "none";
        }

        // When the user starts to type something inside the password field
        myInput.onkeyup = function () {
            // Validate lowercase letters
            var lowerCaseLetters = /[a-z]/g;
            if (myInput.value.match(lowerCaseLetters)) {
                letter.classList.remove("invalid");
                letter.classList.add("valid");
            } else {
                letter.classList.remove("valid");
                letter.classList.add("invalid");
            }

            // Validate capital letters
            var upperCaseLetters = /[A-Z]/g;
            if (myInput.value.match(upperCaseLetters)) {
                capital.classList.remove("invalid");
                capital.classList.add("valid");
            } else {
                capital.classList.remove("valid");
                capital.classList.add("invalid");
            }

            // Validate numbers
            var numbers = /[0-9]/g;
            if (myInput.value.match(numbers)) {
                number.classList.remove("invalid");
                number.classList.add("valid");
            } else {
                number.classList.remove("valid");
                number.classList.add("invalid");
            }

            // Validate specialcharacter
            var spcharacter = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if (myInput.value.match(spcharacter)) {
                specialcharacter.classList.remove("invalid");
                specialcharacter.classList.add("valid");
            } else {
                specialcharacter.classList.remove("valid");
                specialcharacter.classList.add("invalid");
            }

            // Validate length
            if (myInput.value.length >= 8) {
                length.classList.remove("invalid");
                length.classList.add("valid");
            } else {
                length.classList.remove("valid");
                length.classList.add("invalid");
            }
        }
    }

    static showHidePassword() {
        $("#show_hide_password a").on('click', function (event) {
            event.preventDefault();
            if ($('#show_hide_password input').attr("type") === "text") {
                $('#show_hide_password input').attr('type', 'password');
            } else if ($('#show_hide_password input').attr("type") === "password") {
                $('#show_hide_password input').attr('type', 'text');
            }
        });
    }

    static applyCoupon()
    {
        jQuery("#submit_coupon").click(function () {
            var coupon_code = $("#coupon_code").val();
            if (coupon_code) {
                var params = {
                    'couponCode': jQuery("#coupon_code").val(),
                    'planId': jQuery("#package_id").val()
                };

                jQuery.ajax({
                    url: "/api/v1/coupon",
                    type: "get",
                    async: false,
                    data: params,
                    success: function (couponResult) {
                        response = couponResult.data;
                        if (response.id) {
                            response = JSON.parse(JSON.stringify(response));

                            jQuery('#coupon_id').val(response.id);
                            let USDollar = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });
                            var packagePrice = jQuery("#price").val();

                            var discountAmount = 0;
                            if (response.discount_type === 'fixed_amount') {
                                discountAmount = response.discount_amount / 100;
                            } else if (response.discount_type === 'percentage') {
                                discountAmount = (packagePrice * response.discount_percentage) / 100;
                            }

                            var total = parseFloat(packagePrice) - parseFloat(discountAmount);
                            var couponHtml = '<div class="row mb-3">' +
                                    '<div class="col-8">' + response.id + ' - ' + USDollar.format(discountAmount) + ' coupon applied. <a href="javascript:void(0)" id="removeBtn">Remove</a></div>' +
                                    '<div class="col-4 text-right"><strong> - ' + USDollar.format(discountAmount) + '</strong></div>' +
                                    '</div>' +
                                    '<div class="row">' +
                                    '<div class="col-8"><strong>Total</strong></div>' +
                                    '<div class="col-4 text-right"><strong>' + USDollar.format(total) + '</strong></div>';

                            jQuery('#applyCouponContent').html(couponHtml);
                            jQuery("#applyCouponForm").addClass("d-none");
                            jQuery("#couponCodeError").addClass("d-none");
                        } else {
                            jQuery("#couponCodeError").removeClass("d-none");
                            jQuery("#couponCodeError").text(couponResult.message);
                        }
                    }
                });
            } else {
                jQuery("#couponCodeError").removeClass("d-none");
                jQuery("#couponCodeError").text("Coupon code is required.");
            }
        });
    }

    static removeCoupon() {
        jQuery(document).on('click', "#removeBtn", function (event) {
            jQuery("#coupon_id").val('');
            jQuery("#coupon_code").val('');
            jQuery("#applyCouponContent").html(""); // Remove the coupon apply content
            jQuery("#applyCouponForm").addClass("d-none"); // Hide the Coupon Form
            jQuery("#addCouponButton").removeClass("d-none"); // Show add coupon button
        });
    }

    static addCoupon() {
        jQuery(document).on('click', "#addCouponButton", function (event) {
            jQuery("#applyCouponForm").removeClass("d-none");
            jQuery("#addCouponButton").addClass("d-none");
        });
    }
}

module.exports = ShowPlatformCompanyCreate;