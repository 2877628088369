
class ShowPlatformUser
{

    static init()
    {
        this.activeInactiveBind();
    }

    static activeInactiveBind() {
        jQuery(document).on("click", ".active-inactive", function (event) {
            ShowPlatformUser.loadModal(jQuery(this).attr("data-id"));
        });
    }

    static loadModal(id)
    {
        $.ajax({
            url: "users/modal/" + id,
            type: 'GET',
            success: function (response) {
                jQuery("#confirmModal").remove();

                jQuery('body').append(response);

                jQuery("#confirmModal").modal({
                    backdrop: 'static',
                    keyboard: false,
                    show: true,
                    focus: true
                });

                jQuery("#confirmModal").modal("show");
            }
        });
    }

    static saveBind()
    {
        $('#form-user-delete').on('submit',  function (e) {

            var id = $('#userId').val();
            var token = $("meta[name='csrf-token']").attr("content");

            $.ajax({
                url: "users/" + id,
                type: 'DELETE',
                data: {
                    "id": id,
                    "_token": token
                },
                success: function (response) {
                    console.log(response);
                }
            });
        });
    }
}

module.exports = ShowPlatformUser;