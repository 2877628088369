class ChargeCheckout {

    static init(formName) {

        this.newMethod();
        this.validate(formName);
        this.validatePaymentForm(formName);
        this.validateCardInput();
        this.applyCoupon();
        this.removeCoupon();
    }

    static newMethod() {
        //exiry year validation'
        jQuery.validator.addMethod('length2or4', function (element) {
            return element.length == 2 || element.length == 4 ? true : false;
        }, "Expiration year must be 2 or 4 digit long.");

        //cvv validation'
        jQuery.validator.addMethod('length3or4', function (element) {
            return element.length == 3 || element.length == 4 ? true : false;
        }, "CVV must be 3 or 4 digit long.");
    }

    static validate(formName) {
        var rules = {
            first_name: {
                required: true
            },
            last_name: {
                required: true
            },
            email: {
                required: true,
                email: true
            },
            phone_no: {
                required: true,
            },
            address: {
                required: true
            },
            country: {
                required: true
            },
            state: {
                required: true
            },
            city: {
                required: true
            },
            zip_code: {
                required: true
            }
        };

        var messages = {
            first_name: {
                required: "First Name is required."
            },
            last_name: {
                required: "Last Name is required."
            },
            email: {
                required: "Email is required."
            },
            phone_no: {
                required: "Phone number is required."
            },
            address: {
                required: "Address is required."
            },
            country: {
                required: "Country is required."
            },
            state: {
                required: "State is required."
            },
            city: {
                required: "City is required."
            },
            zip_code: {
                required: "Postal/Zip code is required."
            }
        };

        var options = {
            rules: rules,
            messages: messages,
            errorClass: 'text-danger',
            onsubmit: true,
            debug: false
        };

        jQuery(formName).validate(options);
    }

    static validatePaymentForm(formName) {
        var rules = {
            card_no: {
                required: true,
                maxlength: 16
            },
            expiry_month: {
                required: true,
                maxlength: 2
            },
            expiry_year: {
                required: true,
                maxlength: 4,
                length2or4: true
            },
            cvv: {
                required: true,
                maxlength: 4,
                length3or4: true
            }
        };

        var messages = {
            card_no: {
                required: "Card Number is required."
            },
            expiry_month: {
                required: "Expiration month is required."
            },
            expiry_year: {
                required: "Expiration year is required."
            },
            cvv: {
                required: "CVV is required."
            }
        };

        var options = {
            rules: rules,
            messages: messages,
            errorClass: 'text-danger',
            onsubmit: true,
            errorContainer: "#errorContainer",
            errorLabelContainer: "#errorLabelContainer",
            wrapper: "li",
            errorElement: "div",
            debug: false
        };

        jQuery(formName).validate(options);
    }

    static validateCardInput() {
        $(document).ready(function () {
            document.getElementById('card_no').addEventListener('input', function () {
                let inputValue = this.value;
                let maxDigits = 16;
                inputValue = inputValue.replace(/\D/g, '');
                if (inputValue.length > maxDigits) {
                    inputValue = inputValue.slice(0, maxDigits);
                }
                this.value = inputValue;
            });
            document.getElementById('expiry_month').addEventListener('input', function () {
                let inputValue = this.value;
                let maxDigits = 2;
                inputValue = inputValue.replace(/\D/g, '');
                if (inputValue.length > maxDigits) {
                    inputValue = inputValue.slice(0, maxDigits);
                }
                this.value = inputValue;
            });
            document.getElementById('expiry_year').addEventListener('input', function () {
                let inputValue = this.value;
                let maxDigits = 2;
                inputValue = inputValue.replace(/\D/g, '');
                if (inputValue.length > maxDigits) {
                    inputValue = inputValue.slice(0, maxDigits);
                }
                this.value = inputValue;
            });
            document.getElementById('cvv').addEventListener('input', function () {
                let inputValue = this.value;
                let maxDigits = 4;
                inputValue = inputValue.replace(/\D/g, '');
                if (inputValue.length > maxDigits) {
                    inputValue = inputValue.slice(0, maxDigits);
                }
                this.value = inputValue;
            });
        });
    }

    static applyCoupon() {
        $(document).on('click', "#addCouponButton", function () {
            var coupon_code = $("#coupon_code").val();
            if (coupon_code) {
                var params = {
                    'couponCode': $("#coupon_code").val(),
                    'planId': $("#plan_id").val()
                };

                $.ajax({
                    url: "/api/v1/coupon",
                    type: "get",
                    async: false,
                    data: params,
                    success: function (couponResult) {
                        response = couponResult.data;
                        if (response.id) {
                            response = JSON.parse(JSON.stringify(response));

                            $('#coupon_id').val(response.id);
                            let USDollar = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });
                            var packagePrice = $("#price").val();

                            var discountAmount = 0;
                            if (response.discount_type === 'fixed_amount') {
                                discountAmount = response.discount_amount / 100;
                            } else if (response.discount_type === 'percentage') {
                                discountAmount = (packagePrice * response.discount_percentage) / 100;
                            }

                            var total = parseFloat(packagePrice) - parseFloat(discountAmount);
                            var couponHtml = '<div class="row mb-3">' +
                                '<div class="col-8">' + response.id + ' - ' + USDollar.format(discountAmount) + ' coupon applied. <a href="javascript:void(0)" id="removeBtn">Remove</a></div>' +
                                '<div class="col-4 text-right"><strong> - ' + USDollar.format(discountAmount) + '</strong></div>' +
                                '</div>' +
                                '<div class="row">' +
                                '<div class="col-8"><strong>Total</strong></div>' +
                                '<div class="col-4 text-right"><strong>' + USDollar.format(total) + '</strong></div>';

                            $('#applyCouponContent').html(couponHtml);
                            $('#final_price_tag_detail').text(`${USDollar.format(total)} due today`);
                            $("#applyCouponForm").addClass("d-none");
                            $("#couponCodeError").addClass("d-none");
                        } else {
                            $("#couponCodeError").removeClass("d-none");
                            $("#couponCodeError").text(couponResult.message);
                        }
                    }
                });
            } else {
                $("#couponCodeError").removeClass("d-none");
                $("#couponCodeError").text("Coupon code is required.");
            }
        });
    }

    static removeCoupon() {
        $(document).on('click', "#removeBtn", function (event) {
            $("#coupon_id").val('');
            $("#coupon_code").val('');
            $("#applyCouponContent").html(""); // Remove the coupon apply content
            let USDollar = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            $('#final_price_tag_detail').text(`${USDollar.format($("#price").val())} due today`);
            $("#applyCouponForm").removeClass("d-none"); // Show add coupon button
        });
    }
}

module.exports = ChargeCheckout;